<template>
    <v-container fluid>

        <h3><v-icon @click="goBackToPreviousPage">mdi-chevron-left</v-icon>Charger Configuration</h3>
        <br>
        <div v-if="data" class="text-detail">
            <span>{{ data.chargerModelName }}-{{ data.serialNumber }}-{{ data.qrCode }}</span>
        </div>
        <br>
        <v-card v-if="data">
            <v-card-text class="menu-charger">
                <v-row class="mt-1" v-if="data.status == 'loggedOut'">
                    <v-col cols="6" class="text-detail-left">
                        <span @click="loginCharger">Log In Charger</span>
                    </v-col>
                    <v-col cols="6" class="text-detail-right">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
                <v-divider class="mt-2" v-if="data.status == 'loggedOut'"></v-divider>

                <v-row class="mt-1" v-if="data.status !== 'loggedOut'">
                    <v-col cols="6" class="text-detail-left">
                        <span @click="logOutCharger">Log Out Charger</span>
                    </v-col>
                    <v-col cols="6" class="text-detail-right">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
                <v-divider class="mt-2" v-if="data.status !== 'loggedOut'"></v-divider>

                <v-row class="mt-1" v-if="data.status == 'loggedOut'">
                    <v-col cols="6" class="text-detail-left">
                        <span @click="removeCharger">Remove Charger</span>
                    </v-col>
                    <v-col cols="6" class="text-detail-right">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
                <v-divider class="mt-2" v-if="data.status == 'logout'"></v-divider>

            </v-card-text>
        </v-card>
        <loading v-else-if="initializeLoading"></loading>
        <div class="red--text text-center" v-else-if="initializeError">
            {{ initializeError }}
        </div>
        <div class="text-center" v-else>
            Charging Station or Connector not found
        </div>
        <v-col cols="auto">
            <v-dialog width="100%"  v-model="dialog.isActive" :persistent="true">
                <template>
                    <v-card class="popup-wrapper" >
                        <v-toolbar color="primary" dark>
                            <v-toolbar-title class="flex-grow-1">{{ dialog.title }}</v-toolbar-title>
                            <v-btn icon @click="dialog.isActive = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="text-center">
                            <img width="158" height="189" class="center-svg" :src="dialog.imgSvg" alt="SVG" />
                            <div class="caption-size" v-if="!dialog.isLogout">Are you sure you want to remove charger?</div>
                            <div class="caption-size" v-if="dialog.isLogout">Are you sure you want to logout charger?</div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-row justify="center" align="center" cols="12" sm="6" md="6">
                                <v-col cols="6">
                                    <v-btn block @click="dialog.isActive = false">No</v-btn>
                                </v-col>
                                <v-col cols="6" v-if="dialog.isLogout">
                                    <v-btn block @click="logoutAction" color="primary">Yes</v-btn>
                                </v-col>
                                <v-col cols="6" v-if="!dialog.isLogout">
                                    <v-btn block @click="removeAction" color="primary">Yes</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-container>
</template>

<script>
import Vue from "vue";
import loginChargerWarning from '@/assets/images/svg/loginChargerWarning.svg';
import Loading from "@/components/Loading.vue";
import coreApiClient from "@/services/apis/coreApiClient";
import stateManager from "@common/services/stateManager";

export default Vue.extend({
    components: { Loading },
    data() {
        return {
            initializeLoading: false,
            initializeError: null,
            data: null,
            dialog: {
                isActive: false,
                isLogout: true,
                imgSvg: loginChargerWarning,
                title: 'Warning'
            }
        };
    },
    methods: {
        async loginCharger() {
            this.$router.push(`/app/my_charger/login_charger_m?charger_code=${this.data.qrCode}`);
        },
        async getCurrentInfo(id, connectorId) {
            const objCharger = stateManager.get("objCharger");
            const keyChargingStations = id + ':' + connectorId;
            const charger = objCharger[keyChargingStations]

            const result = await coreApiClient.callRaw("chargingStations", "publicGetConnectorInfo", {
                id: charger.id,
                connectorId: charger.connectorId,
            }, null, false, keyChargingStations);
            return result
        },
        async logOutCharger() {
            this.dialog = {
                ...this.dialog,
                isActive: true,
                isLogout: true,
            }
        },
        async removeCharger() {
            this.dialog = {
                ...this.dialog,
                isActive: true,
                isLogout: false,
            }
        },
        async removeAction() {
            const id = this.$route.params.id;
            const connectorId = this.$route.params.connectorId;
            const objCharger = stateManager.get("objCharger");
            delete objCharger[id + ':' + connectorId]
            stateManager.set("objCharger", objCharger);
            this.$router.push(`/app/my_charger/home_m`);
        },
        async logoutAction() {
            const id = this.$route.params.id;
            const connectorId = this.$route.params.connectorId;
            const objCharger = stateManager.get("objCharger");
            objCharger[id + ':' + connectorId].status = 'loggedOut';
            stateManager.set("objCharger", objCharger);
            this.$router.push(`/app/my_charger/home_m`);
        },
        async initialize() {
            const id = this.$route.params.id;
            const connectorId = this.$route.params.connectorId;
            this.initializeLoading = true;

            const objCharger = stateManager.get("objCharger");

            this.data = objCharger[id + ':' + connectorId];
            this.initializeLoading = false;
        },
        goBackToPreviousPage() {
            this.$router.push(`/app/my_charger/home_m`);
        },
    },
    async created() {
        await this.initialize();
    },
});
</script>
<style scoped>
.h3 {
    margin-left: 8px;
    /* Khoảng cách giữa biểu tượng mũi tên và tiêu đề */
    display: inline;
}

.icon-title {
    display: inline-flex;
    align-items: center;
    /* Căn chỉnh các phần tử theo chiều dọc */
}


.submit-btn-row {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    /* Khoảng cách từ lề dưới của màn hình */
    justify-content: space-around
}

.submit-btn {
    border: 1px solid;
    /* Thiết lập viền */
    border-radius: 4px;
    /* Định dạng các góc của nút */
}

.submit-btn-span {
    color: #fff !important;
}

.center-svg {
    display: block;
    /* Đảm bảo ảnh nằm trên một dòng */
    margin: 0 auto;
    /* Căn giữa theo chiều ngang */
}

.caption-size {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #333333;
}

.caption-false {

    text-align: left;
}

.caption-size-false {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;


    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 24px;
}

.image-detail {
    width: 153px;
    height: 151.05px;
    margin-left: auto;
    margin-right: auto;
}

.text-detail {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.text-detail-left {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.text-detail-right {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
}

.v-card .v-divider:last-child {
    display: none;
}

.menu-charger {
    padding-top: 1px;
}
.popup-wrapper {
  overflow: hidden; /* Vô hiệu hóa cuộn trong dialog */
}
</style>
  